<template>
  <div>
    <!-- <div>
      <img src="../../assets/img/dyBanner.png" style="display:block;width:100%;">
    </div> -->
    <section class="companyNews" v-html="companyData" >
      
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
     companyData:'',
    };
  },
 
  methods: {
    selectCompanyNews: function() {
      let that = this;
      let id=this.$route.query.id; 
      that.$http(that.$api.selectCompanyNewsById+'?company_news_id='+id,{})
        .then(res => {
          that.companyData=res.data.object.companyNewsContent
        })
        .catch(error => {
          console.log(error);
        });
    },
    
  },
   mounted: function() {
    //调取本地的get(就在下面)
    this.selectCompanyNews()
  },
};
</script>

<style> 
.companyNews{
  display: block;
  width: 1000px;
  margin: 90px auto;
  animation: fadeInUp 1s ease 0s backwards;
  -webkit-animation: fadeInUp 1s ease 0s backwards;
}
.companyNews img{
  display: block!important;
  width: 70%!important;
  margin: 0 auto!important;
}
</style>